body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SF Pro Text";
  font-style: normal;
}

*,
::after,
::before {
  box-sizing: border-box;
}